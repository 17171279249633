import { Page } from "../page/Page";

export function IntegrityPage() {
  return (
    <Page>
      <h2>Integritetspolicy</h2>
      <h3>A. Introduktion</h3>
      <ol>
        <li>
          Våra hemsidebesökares integritet är väldigt viktig för oss, och vi är
          fast beslutna att skydda den. Denna policy förklarar vad vi gör med
          dina personliga data.
        </li>
        <li>
          Genom att samtycka till att vi använder kakor i enlighet med villkoren
          i denna policy när du besöker vår hemsida för första gången tillåter
          du oss att använda kakor varje gång du besöker vår hemsida.
        </li>
      </ol>
      <h3>B. Erkännande</h3>
      <p>
        Detta dokument skapades med en mall från SEQ Legal (
        <a href="https://seqlegal.com">seqlegal.com</a>) och ändrades av Website
        Planet (
        <a href="https://www.websiteplanet.com">www.websiteplanet.com</a>) och
        Säkerhetskolla.
      </p>
      <h3>C. Insamling av personlig information</h3>
      <p>
        Följande typer av personlig information kan samlas in, lagras och
        användas:
      </p>
      <ol>
        <li>
          information om din dator, inklusive IP-adress, geografisk plats,
          webbläsartyp och -version samt operativsystem;
        </li>
        <li>
          information om dina besök på och din användning av denna hemsida,
          inklusive referenskällan, besökets längd, sidvisningar och hemsidans
          navigeringsvägar;
        </li>
        <li>
          information som du anger när du registrerar dig på vår hemsida, till
          exempel din e-postadress;
        </li>
        <li>
          information som du anger när du skapar en profil på vår hemsida, till
          exempel ditt namn, kön, relationsstatus, din födelsedag, dina
          profilbilder, intressen, hobbyer samt uppgifter om din utbildning och
          anställning;
        </li>
        <li>
          information som du anger för att prenumerera på våra e-postmeddelanden
          och/eller nyhetsbrev, till exempel ditt namn och din e-postadress;
        </li>
        <li>information som du anger när du använder vår hemsidas tjänster;</li>
        <li>
          information som genereras när du använder vår hemsida, inklusive när,
          hur ofta och under vilka omständigheter du använder den;
        </li>
        <li>
          information som har att göra med allt du köper, tjänsterna du använder
          eller transaktioner som du gör via vår hemsida, inklusive ditt namn,
          telefonnummer, din adress, e-postadress och dina kreditkortsuppgifter;
        </li>
        <li>
          information som du publicerar på vår hemsida med avsikt att publicera
          den på internet, inklusive ditt användarnamn, dina profilbilder och
          innehållet i dina inlägg;
        </li>
        <li>
          information i alla former av kommunikation som du skickar till oss via
          e-post eller vår hemsida, inklusive dess innehåll och metadata;
        </li>
        <li>all annan personlig information som du skickar till oss.</li>
      </ol>
      <p>
        Innan du lämnar ut en annan persons personliga information till oss
        behöver du ha den personens samtycke till att den personliga
        informationen både utlämnas och behandlas i enlighet med denna policy.
      </p>
      <h3>D. Användning av din personliga information</h3>
      <p>
        Personlig information som skickas till oss via vår hemsida kommer att
        användas för de syften som anges i denna policy eller på de relevanta
        sidorna på hemsidan. Vi kan använda din personliga information för
        följande:
      </p>
      <ol>
        <li>att administrera vår hemsida och verksamhet;</li>
        <li>att anpassa vår hemsida åt dig;</li>
        <li>
          att göra det möjligt för dig att använda tjänsterna som är
          tillgängliga på vår hemsida;
        </li>
        <li>att skicka varor som du har köpt via vår hemsida till dig;</li>
        <li>att leverera tjänster som är köpta via vår hemsida;</li>
        <li>
          att skicka kontoutdrag, fakturor och betalningspåminnelser till dig
          och samla in betalningar från dig;
        </li>
        <li>
          att skicka kommersiell kommunikation som inte är i
          marknadsföringssyften;
        </li>
        <li>att skicka e-postmeddelanden som du specifikt har begärt;</li>
        <li>
          att skicka vårt nyhetsbrev via e-post till dig om du har begärt det
          (du kan informera oss när som helst om du inte längre behöver
          nyhetsbrevet);
        </li>
        <li>
          att skicka marknadskommunikation till dig som rör vår verksamhet eller
          noggrant utvalda tredje parters verksamhet som vi tror att kan vara av
          intresse för dig per post eller, där du specifikt har samtyckt till
          detta, via e-post eller liknande teknologi (du kan informera oss när
          som helst om du inte längre behöver marknadskommunikation);
        </li>
        <li>
          att ge tredje parter statistisk information om våra användare (men
          dessa tredje parter kommer inte att kunna identifiera en enskild
          användare från informationen);
        </li>
        <li>
          att hantera förfrågningar och klagomål från eller om dig angående vår
          hemsida;
        </li>
        <li>att hålla vår hemsida säker och förebygga bedrägerier;</li>
        <li>
          att bekräfta ditt samtycke av vår hemsidas användarvillkor (inklusive
          övervakning av privata meddelanden som skickats via vår hemsidas
          privata meddelandetjänst);
        </li>
        <li>samt annan användning</li>
      </ol>
      <p>
        Om du skickar personlig information för publicering på vår hemsida,
        kommer vi att publicera och på annat sätt använda den informationen i
        enlighet med det tillstånd du ger oss.
      </p>
      <p>
        Dina sekretessinställningar kan användas för att begränsa publiceringen
        av din information på vår hemsida och kan justeras med hemsidans
        sekretesskontroller.
      </p>
      <p>
        Vi kommer inte att överlämna din personliga information till tredje part
        för deras eller någon annan tredje parts direkta marknadsföring utan
        ditt uttryckliga medgivande.
      </p>
      <h3>E. Utlämning av personlig information</h3>
      <p>
        Vi kan lämna ut din personliga information till någon av våra anställda,
        styrelseledamöter, försäkringsbolag, professionella rådgivare, ombud,
        leverantörer eller underleverantörer som är rimligt nödvändigt för de
        syften som anges i denna policy.
      </p>
      <p>
        Vi kan lämna ut din personliga information till alla medlemmar i vår
        företagsgrupp (detta innebär våra dotterbolag, vårt holdingbolag och
        alla dess dotterbolag) som är rimligt nödvändigt för de syften som anges
        i denna policy.
      </p>
      <p>Vi kan utlämna din personliga information:</p>
      <ol>
        <li>i den mån vi är skyldiga att göra det enligt lag;</li>
        <li>i samband med pågående eller framtida domstolsförfaranden;</li>
        <li>
          för att upprätta, utöva eller försvara våra juridiska rättigheter
          (inklusive att lämna ut information till andra i syfte att förebygga
          bedrägerier och förminska kreditrisken);
        </li>
        <li>
          till köparen (eller den blivande köparen) av alla företag eller
          tillgångar som vi är i process (eller överväger) att sälja;
        </li>
        <li>
          och till alla personer som vi rimligen anser att kan begära av domstol
          eller annan behörig myndighet att vi lämnar ut den personliga
          informationen om, enligt vår rimliga mening, en sådan domstol eller
          myndighet rimligen skulle kunna beordra att vi utlämnar den personliga
          informationen.
        </li>
      </ol>
      <p>
        Vi kommer inte att lämna ut din personliga information till tredje part
        förutom i de fall som anges i denna policy.
      </p>
      <h3>F. Internationell dataöverföring</h3>
      <ol>
        <li>
          Information som vi samlar in kan lagras, behandlas i och överföras
          mellan något av de länder vi är verksamma i för att göra det möjligt
          för oss att använda informationen i enlighet med denna policy.
        </li>
        <li>
          Information som vi samlar in kan överföras till följande länder som
          saknar dataskyddslagar motsvarande de som gäller i Europeiska
          ekonomiska samarbetsområdet: USA, Ryssland, Japan, Kina och Indien.
        </li>
        <li>
          Personlig information som du publicerar på vår hemsida eller skickar
          in för publicering på vår hemsida kan vara tillgänglig via internet
          världen runt. Vi kan inte förhindra att informationen används eller
          missbrukas av andra.
        </li>
        <li>
          Du samtycker uttryckligen till överföring av personlig information som
          beskrivs i detta avsnitt F.
        </li>
      </ol>
      <h3>G. Bevarande av personlig information</h3>
      <ol>
        <li>
          Detta avsnitt G beskriver våra policyer och förfaranden för
          datalagring, som är utformade för att se till att vi följer våra
          juridiska skyldigheter angående lagring och radering av personlig
          information.
        </li>
        <li>
          Personlig information som vi behandlar för något syfte eller syften
          kommer inte att förvaras längre än vad som är nödvändigt för det
          syftet eller de syftena.
        </li>
        {/* Utan att det påverkar artikel G-2, kommer vi vanligtvis att radera personuppgifter som faller inom kategorierna nedan vid det datum och den tid som anges nedan:
personuppgiftstyp kommer att raderas {ANGE DATUM/TID}; och
{ANGE YTTERLIGARE DATUM/TIDER}. */}
        <li>
          Oavsett övriga bestämmelser i detta avsnitt G, kommer vi att behålla
          dokument (inklusive elektroniska dokument) som innehåller
          personuppgifter:
          <ol>
            <li>i den mån vi är skyldiga att göra det enligt lag;</li>
            <li>
              om vi tror att handlingarna kan vara relevanta för pågående eller
              framtida domstolsförfaranden;
            </li>
            <li>
              och för att upprätta, utöva eller försvara våra juridiska
              rättigheter (inklusive att lämna ut information till andra i syfte
              att förebygga bedrägerier och förminska kreditrisken).
            </li>
          </ol>
        </li>
      </ol>
      <h3>H. Din personliga informations säkerhet</h3>
      <ol>
        <li>
          Vi kommer att vidta rimliga tekniska och organisatoriska
          försiktighetsåtgärder för att förhindra förlust, missbruk eller
          ändring av din personliga information.
        </li>
        <li>
          Vi kommer att lagra all personlig information som du tillhandahåller
          på våra säkra (lösenords- och brandväggsskyddade) servrar.
        </li>
        <li>
          Alla elektroniska finansiella transaktioner som görs via vår hemsida
          skyddas av krypteringsteknologi.
        </li>
        <li>
          Du medger att informationsöverföring via internet i sig är osäkert,
          och vi kan inte garantera säkerheten för data som skickas via
          internet.
        </li>
        <li>
          Du ansvarar för att hålla lösenordet du använder för att komma åt vår
          hemsida konfidentiellt; vi kommer inte att be dig om ditt lösenord
          (förutom när du loggar in på vår hemsida).
        </li>
      </ol>
      <h3>I. Ändringar</h3>
      <p>
        Vi kan uppdatera denna policy då och då genom att publicera en ny
        version på vår hemsida. Du bör kontrollera denna sida emellanåt för att
        se till att du förstår eventuella ändringar i denna policy. Vi kan
        meddela dig om ändringar av denna policy via e-post eller via vår
        hemsidas privata meddelandesystem.
      </p>
      <h3>J. Dina rättigheter</h3>
      <p>
        Du kan be oss att ge dig all personlig information vi har om dig;
        tillhandahållande av sådan information kommer att omfattas av följande:
      </p>
      {/* betalning av en avgift {ANGE AVGIFT OM RELEVANT}; och */}
      <ol>
        <li>
          tillhandahållande av lämpligt bevis på din identitet
          {/* ({ÄNDRA TEXTEN SÅ ATT DEN REFLEKTAR DIN POLICY för detta ändamål, vi accepterar vanligtvis ett foto av ditt pass som har certifierats av en notarie, plus en kopia av en samhällsserviceräkning som visar din aktuella adress}). */}
        </li>
      </ol>
      <p>
        Vi kan undanhålla den personliga information som du begär enligt vad som
        tillåts enligt lag.
      </p>
      <p>
        Du kan när som helst be oss att inte behandla din personliga information
        för marknadsföringsändamål.
      </p>
      <p>
        I praktiken kommer du vanligtvis att antingen uttryckligen acceptera vår
        användning av din personliga information för marknadsföringsändamål,
        eller så kommer vi att ge dig möjligheten att välja bort användningen av
        din personliga information för marknadsföringsändamål.
      </p>
      <h3>K. Tredje parts hemsidor</h3>
      <p>
        Vår hemsida innehåller hyperlänkar till och detaljer om tredje parts
        hemsidor. Vi har ingen kontroll över, och är inte ansvariga för, tredje
        parters sekretesspolicyer och praxis.
      </p>
      <h3>L. Uppdatering av information</h3>
      <p>
        Låt oss veta om den personliga information som vi har om dig behöver
        korrigeras eller uppdateras.
      </p>
      <h3>M. Kakor</h3>
      <p>
        Vår hemsida använder kakor. En kaka är en fil som innehåller en
        identifierare (en sträng med bokstäver och siffror) som skickas av en
        webbserver till en webbläsare och lagras av webbläsaren. Identifieraren
        skickas därefter tillbaka till servern varje gång webbläsaren begär en
        sida från servern. Kakor kan antingen vara “ständiga kakor” eller
        “sessionskakor”: en ständig kaka lagras av en webbläsare och förblir
        giltig till det angivna utgångsdatumet, såvida den inte raderas av
        användaren innan utgångsdatumet; en sessionskaka, å andra sidan, kommer
        att gå ut i slutet av användarsessionen, när webbläsaren är stängd.
        Kakor innehåller vanligtvis inte information som personligen
        identifierar en användare, men personlig information som vi lagrar om
        dig kan vara länkad till informationen som är lagrad i och erhållen från
        kakor. Vi använder både ständiga och sessionskakor på vår hemsida.
      </p>
      <ol>
        <li>
          Nedan anges namnen på de kakor vi använder på vår hemsida och de
          syften som de används för:
        </li>
        <ol>
          <li>
            vi använder Google Analytics och Adsense på vår hemsida för att
            känna igen en dator när en användare besöker hemsidan, spårar
            användare när de bläddrar på hemsidan,{" "}
            {/* möjliggör användning av en kundvagn på hemsidan */}
            förbättrar hemsidans användbarhet, analyserar användningen av
            hemsidan, administrerar hemsidan, förhindrar bedrägerier och
            förbättrar hemsidans säkerhet, anpassar hemsidan för varje
            användare, målannonser som kan vara av särskilt intresse för
            specifika användare;{" "}
          </li>
        </ol>
        <li>
          De flesta webbläsare låter dig vägra att acceptera kakor – till
          exempel:
          <ol>
            <li>
              i Internet Explorer (version 10) kan du blockera kakor med hjälp
              av inställningarna för att upphäva kakhantering genom att klicka
              på “Verktyg”, “Internetalternativ”, “Sekretess” och sedan
              “Avancerat”;
            </li>
            <li>
              i Firefox (version 24) kan du blockera alla kakor genom att klicka
              på “Verktyg”, “Inställningar”, “Sekretess & säkerhet”, välja
              “Anpassad innehållsblockering” och klicka på “Alla kakor”;
            </li>
            <li>
              och i Chrome (version 29) kan du blockera alla kakor genom att
              öppna menyn “Inställningar” och klicka på “Inställningar för
              webbplatsen” under “Sekretess och säkerhet” och sedan avmarkera
              “Tillåt att webbplatser sparar och läser cookiedata”.
            </li>
          </ol>
        </li>
        <p>
          Om du blockerar alla kakor kommer det att ha en negativ inverkan på
          många hemsidors användbarhet. Om du blockerar kakor kommer du inte att
          kunna använda alla funktioner på vår hemsida.
        </p>
        <li>
          Du kan ta bort kakor som redan har lagrats på din dator – till
          exempel:
        </li>
        <ol>
          <li>
            i Internet Explorer (version 10) måste du manuellt ta bort kakor (du
            kan hitta instruktioner för att göra det på
            <a href="http://support.microsoft.com/kb/278835">
              http://support.microsoft.com/kb/278835
            </a>
            );
          </li>
          <li>
            i Firefox (version 24) kan du ta bort kakor genom att klicka på
            “Verktyg”, “Inställningar”, “Sekretess & säkerhet” och sedan välja
            “Rensa data” under “Kakor och webbplatsdata”;
          </li>
          <li>
            och i Chrome (version 29) kan du ta bort alla kakor genom att öppna
            menyn “Inställningar” och klicka på “Rensa webbinformation” under
            “Sekretess och säkerhet”.
          </li>
        </ol>
        <li>
          Tar du bort kakor kommer det att ha en negativ inverkan på många
          hemsidors användbarhet.
        </li>
      </ol>
    </Page>
  );
}
