import { useMap } from "react-leaflet";
import { EventMarker } from "../eventMarker/EventMarker";

export function MarkerContainer({
  events,
  eventFilter,
  getEventDetails,
  minZoom,
}) {
  const map = useMap();
  if (map.getZoom() > minZoom)
    return (
      <>
        {events.map((e) => {
          const filterCategories = Object.keys(e.filterCategories);

          // Loop through all filter categories present at the event coordinates
          for (let i = 0; i < filterCategories.length; i++) {
            const subCategories = e.filterCategories[filterCategories[i]];

            // For each filter category, loop through all of it's subcategories
            for (let j = 0; j < subCategories.length; j++) {
              // Only show a map marker if it has at least one category matching the active category filter

              if (
                eventFilter?.[filterCategories[i]].subCategories[
                  subCategories[j]
                ]
              ) {
                return (
                  <EventMarker
                    events={e}
                    eventFilter={eventFilter}
                    getEventDetails={getEventDetails}
                    key={`${e.latitude}:${e.longitude}`}
                  />
                );
              }
            }
          }
          return null;
        })}
      </>
    );
}
