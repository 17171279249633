import { useContext, useState, useEffect } from "react";
import { EventContext } from "../context/EventContext";
import styles from "./Event.module.css";

export function Event({ event }) {
  const [context, setContext] = useContext(EventContext);
  const [updated, setUpdated] = useState(false);

  const {
    uuid,
    title,
    sourceUrl,
    detailedLocation,
    latitude,
    longitude,
    boundingBox,
    longDescription,
    severity,
    locationRank,
    createDate,
    modifiedDate,
  } = event;

  useEffect(() => {
    if (context?.uuid === uuid && context?.updated) {
      setUpdated(context.updated);
    }
  }, [context?.updated]);

  const selectEvent = async () => {
    if (context?.uuid !== uuid) {
      const publishedDate = new Date(0);
      publishedDate.setUTCMilliseconds(event.publishedDate);
      const reported = publishedDate.toLocaleString("sv-SE");
      if (locationRank) {
        setContext({
          ...event,
          latlng: [latitude, longitude],
          boundingBox,
          radius: 250,
          severity: severity,
          longDescription,
          detailedLocation,
          reported: reported.toLocaleString(),
          createDate,
          modifiedDate,
        });
      } else {
        setContext({
          ...event,
          latlng: [61.5, 14.0],
          radius: 100,
          severity: severity,
          longDescription,
          detailedLocation,
          reported: reported.toLocaleString(),
          createDate,
          modifiedDate,
        });
      }
    }
  };

  return (
    <div
      className={`${
        uuid === context?.uuid
          ? `${styles.root} ${styles.selected}`
          : styles.root
      }
          ${updated ? ` ${styles.updated}` : ""}
        `}
      tabIndex={0}
      onFocus={selectEvent}
    >
      <h6 className={styles.title}>{title}</h6>
    </div>
  );
}
