import styles from "./eventMarker.module.css";
import { Marker, Tooltip } from "react-leaflet";
import { divIcon, icon } from "leaflet";
import iconBlue from "../../../img/marker-icons/marker-icon.png";
import iconRed from "../../../img/marker-icons/marker-icon-red.png";
import iconYellow from "../../../img/marker-icons/marker-icon-Yellow.png";
import iconGreen from "../../../img/marker-icons/marker-icon-DarkGreen.png";
import iconBlack from "../../../img/marker-icons/marker-icon-Black.png";
import shadowImage from "../../../img/marker-icons/marker-shadow.png";
import { EventPopup } from "../eventPopup/EventPopup";

const iconImage = {
  "4bbbfbd6-467c-4287-9ec9-48466c4c5fb6": iconBlue, // Övriga kategorier
  "185b5f1b-12c2-4490-8524-194b9f55a865": iconGreen, // Trafikrelaterade händelser
  "e0dd3e09-a12c-4357-9917-1c86b355f58b": iconYellow, // Egendomsbrott
  "c7a50215-616c-4316-b22c-2919174ca49b": iconBlack, // Allmän ordning och säkerhet
  "4064d5cc-6e36-42a3-a82b-3e76c7bf53b2": iconRed, // Brott mot personer
};

export function EventMarker({ events, eventFilter, getEventDetails }) {
  const { latitude, longitude, count, latest, filterCategories } = events;
  // console.log(Icon.Default.prototype.options);
  const latestTimestamp = new Date(0);
  latestTimestamp.setUTCMilliseconds(latest.published);

  let filteredCount = 0;

  for (const filterCategory in filterCategories) {
    // Don't count filtered categories

    const categories = filterCategories[filterCategory];
    for (let i = 0; i < categories.length; i++) {
      if (
        eventFilter[filterCategory].active ||
        eventFilter[filterCategory].subCategories[categories[i]]
      )
        filteredCount++;
    }
  }

  const customIcon = icon({
    iconUrl: iconImage[latest.filterCategory]
      ? iconImage[latest.filterCategory]
      : iconBlue,
    iconSize: [26, 41],
    iconAnchor: [13, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowUrl: shadowImage,
    tooltipAnchor: [16, -28],
  });

  const counter = divIcon({
    html: `${filteredCount}`,
    className: styles.counter,
    iconSize: [20, 20],
    iconAnchor: [10, 38],
  });

  return (
    <>
      <Marker
        icon={customIcon}
        position={[latitude, longitude]}
        // riseOnHover={true}
      >
        <Tooltip>
          <div className={styles.tooltip}>
            <p>{latest.category}</p>
            <p>
              {`${latestTimestamp.getFullYear()}-${(
                "0" +
                (latestTimestamp.getMonth() + 1)
              ).slice(-2)}-${("0" + latestTimestamp.getDate()).slice(-2)}`}
            </p>
          </div>
        </Tooltip>
        <EventPopup
          latlng={{ latitude, longitude }}
          eventFilter={eventFilter}
          getEventDetails={getEventDetails}
        />
      </Marker>
      {filteredCount > 1 ? (
        <Marker
          icon={counter}
          position={[latitude, longitude]}
          interactive={false}
        />
      ) : null}
    </>
  );
}
