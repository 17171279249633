import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import IconButton from "../iconButton/IconButton";
import styles from "./PageCounter.module.css";

export function PageCounter({ page, pageCount, prev, next }) {
  return (
    <div className={styles.root}>
      <IconButton
        type="button"
        id="prevEventButton"
        icon={FiChevronLeft}
        width={25}
        height={25}
        onClick={prev}
      />
      <div id={styles.counter}>{`${page + 1} / ${pageCount}`}</div>
      <IconButton
        type="button"
        id="nextEventButton"
        icon={FiChevronRight}
        width={25}
        height={25}
        onClick={next}
      />
    </div>
  );
}
