import { createContext, useState } from "react";

const EventContext = createContext([{}, () => {}]);

function EventProvider(props) {
  const [state, setState] = useState();

  return (
    <EventContext.Provider value={[state, setState]}>
      {props.children}
    </EventContext.Provider>
  );
}

export { EventContext, EventProvider };
