import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMapEvent } from "react-leaflet";
import mapConfig from "../../utils/mapConfig";
import { EditMarker } from "./editMarker/EditMarker";
import { MarkerContainer } from "./markerContainer/MarkerContainer";
// import { Watermark } from "./watermark/Watermark";
import styles from "./Map.module.css";
import { lineSpinner } from "ldrs";
lineSpinner.register();

const { mapAttributes, layerAttributes, areaAttributes, minZoom } = mapConfig;

export function Map({
  update,
  events,
  eventFilter,
  getEvents,
  getEventDetails,
}) {
  const [searchPosition, setSearchPosition] = useState();

  // Add MapEvents component to map to enable event handling on the map
  function MapEvents() {
    const map = useMapEvent("moveend", () => {
      if (getEvents && map.getZoom() > minZoom) {
        const bounds = map.getBounds();
        const center = map.getCenter();

        // Get events for the current map boundaries
        getEvents(
          center.lat,
          center.lng,
          bounds.getWest(),
          bounds.getEast(),
          bounds.getSouth(),
          bounds.getNorth()
        );
      }
    });

    // Set map view to new coordinates
    useEffect(() => {
      if (update?.latlng && update.latlng !== searchPosition) {
        setSearchPosition(update.latlng);
        map.setView(update.latlng, update.zoom);
      }
    }, [update]);
  }

  return (
    <MapContainer id={styles.root} {...mapAttributes}>
      <TileLayer {...layerAttributes} />
      <MapEvents />
      {update?.uuid ? (
        <EditMarker
          key={update.uuid}
          {...update}
          severityColor={areaAttributes.severityColor[update.severity]}
        />
      ) : (
        <MarkerContainer
          events={events?.arr}
          eventFilter={eventFilter}
          getEventDetails={getEventDetails}
          minZoom={minZoom}
        />
      )}
      {events.loading ? (
        <div className={styles.loader}>
          <l-line-spinner
            size={50}
            stroke={2}
            speed={1}
            color={"rgba(80, 80, 80, 0.95)"}
          ></l-line-spinner>
        </div>
      ) : null}
      {/* <Watermark /> */}
    </MapContainer>
  );
}
