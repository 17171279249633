import { Page } from "../page/Page";

export function AboutUsPage() {
  return (
    <Page>
      <p>
        Välkommen till Säkerhetskolla.se - en interaktiv webbplats där användare
        kan utforska aktuella polishändelser på en detaljerad karta för att få
        en överblick över säkerhetsläget i olika områden.
      </p>
      <p>
        Vi är två vänner som delar en gemensam entusiasm för teknik och
        samhällsservice.
        {/* Vad började som ett hobbyprojekt för oss har nu blomstrat till denna plattform.*/}{" "}
        Vi strävar efter att skapa en meningsfull och användbar resurs för alla
        som är intresserade av att hålla sig uppdaterade om säkerhet och
        brottsaktiviteten i Sverige.
      </p>
      <p>
        Genom att använda polisens officiella nyhetsflöde tillhandahåller vi en
        dynamisk karta som visar aktuella händelser och brott {/*i realtid,*/}{" "}
        över hela landet. Vårt mål är att ge allmänheten en överblick över
        brottsaktivitet och bidra till ökad medvetenhet och säkerhet.
      </p>
      {/* <p>
        Säkerhetskolla.se är mer än bara en karta, det är ett uttryck för vår
        önskan att kombinera teknik och samhällsnytta på ett meningsfullt sätt.
        Vi strävar efter att skapa en plattform som är lättillgänglig och
        användarvänlig för alla.
      </p> */}
      <p>
        Vi är tacksamma för att du har valt att besöka vår sida och vi hoppas
        att du hittar den både informativ och användbar. Tack för ditt stöd och
        fortsatt intresse för Säkerhetskolla.
      </p>
      <p>
        Om du har några frågor eller feedback, är du välkommen att kontakta oss
        på:
      </p>
      <p>
        <a href="mailto:kontakta@sakerhetskolla.se">
          kontakta@sakerhetskolla.se
        </a>
      </p>
      <p>
        Vår integritetspolicy kan läsas <a href="/integritet">här</a>
      </p>
    </Page>
  );
}
