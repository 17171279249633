import { useEffect, useState } from "react";
import { getFeed } from "../../utils/api/api";
import { Event } from "../event/Event";

export function EventFeed({ setEvents }) {
  const [feed, setFeed] = useState();

  useEffect(() => {
    async function loadFeed() {
      const events = await getFeed();
      setFeed(events);
      setEvents(events);
    }
    loadFeed();
  }, []);

  return (
    <>
      {!feed ? (
        <h2>Loading...</h2>
      ) : (
        feed.map((item) => <Event key={item.uuid} event={item} />)
      )}
    </>
  );
}
