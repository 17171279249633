import { useEffect, useMemo, useRef, useState, useContext } from "react";
import { Circle, Marker, useMap } from "react-leaflet";
import { EventContext } from "../../context/EventContext";
import mapConfig from "../../../utils/mapConfig";

export function EditMarker({ uuid, latlng, zoom, radius, severity }) {
  const [context, setContext] = useContext(EventContext);
  const [position, setPosition] = useState(latlng);
  const [center, setCenter] = useState(latlng);
  const [areaRadius, setAreaRadius] = useState(radius);
  const [severityColor, setSeverityColor] = useState(
    mapConfig.areaAttributes.severityColor[severity]
  );

  const markerRef = useRef(null);
  const areaRef = useRef(null);
  const map = useMap();

  const eventHandlers = useMemo(
    () => ({
      move(e) {
        setCenter(e.latlng);
      },
      dragend() {
        const marker = markerRef.current;
        const area = areaRef.current;
        if (marker != null) {
          const latlng = marker.getLatLng();
          const radius = area.getRadius();
          setPosition(latlng);
          setContext({
            ...context,
            latlng: [latlng.lat, latlng.lng],
            zoom: map.getZoom(),
            radius,
          });
        }
      },
    }),
    []
  );

  useEffect(() => {
    map.setView(latlng, zoom);
  }, [uuid]);

  useEffect(() => {
    setPosition(latlng);
  }, [latlng]);

  useEffect(() => {
    setAreaRadius(radius);
  }, [radius]);

  useEffect(() => {
    setSeverityColor(mapConfig.areaAttributes.severityColor[severity]);
  }, [severity]);

  return (
    <>
      <Marker
        position={position}
        draggable={true}
        autoPan={true}
        autoPanSpeed={5}
        eventHandlers={eventHandlers}
        ref={markerRef}
      />
      <Circle
        center={center}
        radius={areaRadius}
        color={severityColor}
        fillColor={severityColor}
        fillOpacity={0.3}
        ref={areaRef}
      />
    </>
  );
}
