import styles from "./IconButton.module.css";

function IconButton({
  type = "button",
  onClick,
  icon,
  enabled = true,
  id,
  height = 40,
  width = 40,
}) {
  const CustomTag = icon;

  const conditionalAttributes = {};
  if (!enabled) conditionalAttributes.disabled = true;

  return (
    <button
      type={type}
      className={styles.root}
      onClick={enabled ? onClick : null}
      id={id}
      style={{ height, width }}
      {...conditionalAttributes}
    >
      <CustomTag className={styles.icon} />
    </button>
  );
}

export default IconButton;
