import { NavBar } from "./components/navBar/NavBar";
import { FiHelpCircle } from "react-icons/fi";
import { EventDashboard } from "./components/eventDashboard/EventDashboard";
import { AboutUsPage } from "./components/aboutUsPage/AboutUsPage";
import { Routes, Route } from "react-router-dom";
import { ReactComponent as LogoIcon } from "./img/sakerhetskolla_logo_icon.svg";
import styles from "./App.module.css";
import { IntegrityPage } from "./components/integrityPage/IntegrityPage";
import { Page } from "./components/page/Page";

function App() {
  return (
    <div className={styles.root}>
      <NavBar
        menuItems={[
          {
            id: "main",
            label: <LogoIcon height={65} width={65} />,
            route: "/",
            className: "icon",
          },
          {
            id: "about-us",
            label: <FiHelpCircle style={{ height: 26, width: 26 }} />,
            route: "/om-oss",
            className: "icon",
          },
        ]}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <EventDashboard key="userMap" mode="browse" />
            </>
          }
        />
        <Route path="/om-oss" element={<AboutUsPage />} />
        <Route path="/integritet" element={<IntegrityPage />} />
        <Route
          path="*"
          element={
            <Page>
              <h2>Oj då, kunde inte hitta den sidan...</h2>
              <h3>Felkod: 404</h3>
            </Page>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
