import { useEffect, useState } from "react";

export function useIsMobile() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function handleWindowSizeChange() {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = size.width <= 768 || size.height <= 768;

  return isMobile;
}
