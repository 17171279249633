import styles from "./Page.module.css";

export function Page({ children }) {
  return (
    <div className={styles.root}>
      <h1>
        <img
          src={require("../../img/sakerhetskolla_logo.png")}
          alt="Om Säkerhetskolla"
          width="532"
          height="139"
        />
      </h1>
      {children}
    </div>
  );
}
