import { useIsMobile } from "../../utils/hooks/useIsMobile";
import styles from "./AutocompletePanel.module.css";
import { lineSpinner } from "ldrs";
lineSpinner.register();

export function AutoCompletePanel({ suggestions, goToSearchLocation }) {
  const displayNames = { length: 0 };
  const maxSuggestions = useIsMobile() ? 5 : 10;

  return (
    <div className={styles.root}>
      {suggestions ? (
        suggestions.length > 0 ? (
          suggestions.map((suggestion, i) => {
            const adress = suggestion.displayName.split(", ");
            const displayName = `${adress[0]}, ${adress[1]}, ${adress[2]}`;

            if (
              displayNames.length < maxSuggestions &&
              !displayNames[displayName]
            ) {
              displayNames[displayName] = true;
              displayNames.length++;
              return (
                <div key={`autocomplete${i}`} className={styles.suggestion}>
                  <a
                    href="#"
                    onClick={() => {
                      goToSearchLocation(suggestion, suggestion.displayName);
                    }}
                  >
                    {displayName}
                  </a>
                </div>
              );
            } else return null;
          })
        ) : (
          "Inga förslag hittades..."
        )
      ) : (
        <l-line-spinner
          className={styles.loader}
          size={26}
          stroke={2}
          speed={1}
          color={"rgba(80, 80, 80, 0.95)"}
        ></l-line-spinner>
      )}
    </div>
  );
}
