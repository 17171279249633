import { NavLink } from "react-router-dom";
import styles from "./NavBar.module.css";

export function NavBar({ menuItems }) {
  return (
    <div className={styles.root}>
      <ul className={styles.navList}>
        {menuItems.map(({ id, label, route, className }) => (
          <li
            className={
              className
                ? `${styles.navItem} ${styles[className]}`
                : styles.navItem
            }
            key={`nav_${id}`}
            id={id}
          >
            <NavLink
              end
              to={route}
              className={({ isActive }) =>
                isActive ? styles.link_active : null
              }
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
      {/* <a className={styles.login} href="/login">
        Login
      </a> */}
    </div>
  );
}
