import { useContext, useEffect, useState, useRef } from "react";
import { searchLocation, saveItem } from "../../utils/api/api";
import { EventContext } from "../context/EventContext";
import { Input } from "../input/Input";
import toast, { Toaster } from "react-hot-toast";
import styles from "./EventEditor.module.css";

export function EventEditor({ updateMap }) {
  const [eventContext, setEventContext] = useContext(EventContext);

  const {
    uuid = null,
    title = null,
    longDescription = null,
    category = null,
    subCategory = null,
    detailedLocation = null,
    reported = null,
    sourceUrl: link = null,
    createDate = null,
    modifiedDate = null,
  } = eventContext || {};

  const [formState, setFormState] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
    boundingBox: [],
    radius: 100,
    severity: 2,
    active: true,
  });

  const formStateRef = useRef(formState);

  function updateState() {
    if (eventContext) {
      setFormState({
        ...formState,
        location: eventContext.detailedLocation,
        lat: eventContext.latlng[0],
        lng: eventContext.latlng[1],
        boundingBox: eventContext.boundingBox,
        radius: eventContext.radius,
        severity: eventContext.severity,
      });

      updateMap(
        uuid,
        [eventContext.latlng[0], eventContext.latlng[1]],
        eventContext.zoom,
        eventContext.radius,
        eventContext.severity
      );
    }
  }

  useEffect(updateState, [eventContext]);

  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  const handleChange = (event) =>
    setFormState({ ...formState, [event.target.id]: event.target.value });

  function handleCoordinatesChange(event) {
    handleChange(event);

    updateMap(
      uuid,
      [
        event.target.id === "lat" ? event.target.value : formState.lat,
        event.target.id === "lng" ? event.target.value : formState.lng,
      ],
      undefined,
      formState.radius,
      formState.severity
    );
  }

  function handleRadiusChange(event) {
    handleChange(event);
    setEventContext({ ...eventContext, radius: event.target.value });
    updateMap(
      uuid,
      [formState.lat, formState.lng],
      undefined,
      event.target.value,
      formState.severity
    );
  }

  function handleSeverityChange(event) {
    handleChange(event);
    setEventContext({ ...eventContext, severity: event.target.value });
    updateMap(
      uuid,
      [formState.lat, formState.lng],
      undefined,
      formState.radius,
      event.target.value
    );
  }

  async function handleSearch() {
    const result = await searchLocation(formState.location);
    if (result) {
      setEventContext({
        ...eventContext,
        detailedLocation: formState.location,
        latlng: [result.lat, result.lon],
        boundingBox: result.boundingbox,
      });
      setFormState({
        ...formState,
        lat: result.lat,
        lng: result.lon,
        boundingBox: result.boundingbox,
      });
      updateMap(
        uuid,
        [result.lat, result.lon],
        undefined,
        formState.radius,
        formState.severity
      );
    }
  }

  function handleDefaultSubmit(event) {
    event.preventDefault();
  }

  const handleIgnore = (event) => handleSubmit(event, true);

  async function handleSubmit(event, ignoreItem) {
    event.preventDefault();

    await saveItem({
      ...formStateRef.current,
      active: ignoreItem ? false : true,
      uuid,
    });

    toast("Händelse uppdaterad!");
    setEventContext({ ...eventContext, updated: true });
  }

  return (
    <>
      {eventContext ? (
        <div className={styles.root}>
          <form onSubmit={handleDefaultSubmit}>
            <div>
              <Input
                label="Plats"
                id="location"
                type="text"
                value={formState.location}
                onChange={handleChange}
              />
              <button id="search" onClick={handleSearch}>
                Sök
              </button>
            </div>
            <fieldset>
              <Input
                label="Latitud"
                id="lat"
                type="number"
                step={0.0001}
                value={formState.lat}
                onChange={handleCoordinatesChange}
              />
              <Input
                label="Longitud"
                id="lng"
                type="number"
                step={0.0002}
                value={formState.lng}
                onChange={handleCoordinatesChange}
              />
            </fieldset>
            <Input
              label="Radie"
              id="radius"
              type="range"
              min="100"
              max="2500"
              value={formState.radius}
              onChange={handleRadiusChange}
            />
            <Input
              label="Allvar"
              id="severity"
              type="range"
              min="0"
              max="5"
              value={formState.severity}
              onChange={handleSeverityChange}
            />
            <fieldset>
              <button onClick={handleIgnore}>Ignorera</button>
              <button type="submit" onClick={handleSubmit}>
                Spara
              </button>
            </fieldset>
            <Toaster
              position="center"
              containerStyle={{
                position: "relative",
                left: -50,
              }}
              toastOptions={{
                style: {
                  backgroundColor: "#d5effcdd",
                  border: "1px solid #afafaf",
                },
              }}
            />
          </form>

          <div className={styles.event}>
            <div className={styles.contentContainer}>
              <h4 className={styles.title}>{title}</h4>
              {/* Description */}
              <div className={styles.description}>
                <p>{longDescription}</p>
              </div>
            </div>
            <div className={styles.detailsContainer}>
              {/* ID */}
              <ul className={styles.details}>
                <li>ID:</li>
                <li key={uuid}>{uuid}</li>
              </ul>

              {/* Location */}
              <ul className={styles.details}>
                <li>Plats:</li>
                {detailedLocation?.split(", ").map((text, i) => (
                  <li key={i}>{text}</li>
                ))}
              </ul>

              {/* Details */}
              <ul className={styles.details}>
                <li>Detaljer:</li>
                <li>
                  {subCategory ? `${category} - ${subCategory}` : category}
                </li>
                {createDate === modifiedDate ? (
                  <li>Rapporterad: {reported}</li>
                ) : (
                  <li>Uppdaterad: {modifiedDate}</li>
                )}
                <li>
                  <a href={link} target="_blank" rel="noreferrer">
                    Referens
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
