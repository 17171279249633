import { useState } from "react";
import { useEventFilter } from "../../utils/hooks/useEventFilter";
import { getItems, getItemDetails } from "../../utils/api/api";
import { EventProvider } from "../context/EventContext";
import { EventEditor } from "../eventEditor/EventEditor";
import { EventFeed } from "../eventFeed/EventFeed";
import { FilterPanel } from "../filterPanel/FilterPanel";
import { Map } from "../map/Map";
import styles from "./EventDashboard.module.css";
import { SearchBar } from "../searchBar/SearchBar";

export function EventDashboard({ mode }) {
  const [eventFilter, toggleFilter] = useEventFilter();
  const [filterPanel, setFilterPanel] = useState(window.innerWidth > 1000);
  const toggleFilterPanel = () => setFilterPanel(!filterPanel);
  const [events, setEvents] = useState({ obj: {}, arr: [] });
  const [mapUpdate, setMapUpdate] = useState();
  const updateMap = (uuid, latlng, zoom = 15, radius, severity) =>
    setMapUpdate({ uuid, latlng, zoom, radius, severity });

  async function getEvents(lat, lng, west, east, south, north) {
    setEvents({ ...events, loading: true });
    const eventsArray = await getItems(lat, lng, west, east, south, north);
    updateEvents(eventsArray);
  }

  function updateEvents(eventsArray) {
    const newEvents = {};
    const newEventsArray = [];

    eventsArray.forEach((e) => {
      const key = `${e.latitude}:${e.longitude}`;
      if (!events.obj[key]) {
        newEvents[key] = { ...e, ...events.obj[key] };
        newEventsArray.push(e);
      }
    });
    setEvents({
      obj: { ...events.obj, ...newEvents },
      arr: [...events.arr, ...newEventsArray],
      loading: false,
    });
  }

  async function getEventDetails(uuid, lat, lng) {
    if (!events.obj[`${lat}:${lng}`].details) {
      const eventsArray = await getItemDetails(uuid, lat, lng);
      events.obj[`${lat}:${lng}`].details = eventsArray;
    }

    return events.obj[`${lat}:${lng}`].details;
  }

  return (
    <EventProvider>
      {mode === "admin" ? (
        <div className={styles.root}>
          <div className={styles.feedContainer}>
            <EventFeed setEvents={setEvents} />
          </div>
          <div className={styles.eventContainer}>
            <Map update={mapUpdate} />
            <EventEditor updateMap={updateMap} />
          </div>
        </div>
      ) : (
        <div className={styles.root}>
          <div className={styles.mapContainer}>
            <SearchBar
              updateMap={updateMap}
              toggleFilterPanel={toggleFilterPanel}
            />
            {filterPanel ? (
              <FilterPanel filter={eventFilter} toggleFilter={toggleFilter} />
            ) : null}
            <Map
              update={mapUpdate}
              getEvents={getEvents}
              getEventDetails={getEventDetails}
              events={events}
              eventFilter={eventFilter}
            />
          </div>
        </div>
      )}
    </EventProvider>
  );
}
