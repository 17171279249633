import { useState } from "react";

export function useEventFilter() {
  // State variable for all categories.
  // Needs to match the values present in the DB
  const [filter, setFilter] = useState({
    "c7a50215-616c-4316-b22c-2919174ca49b": {
      label: "Allmän ordning och säkerhet",
      active: true,
      subCategories: {
        Bombhot: true,
        Bråk: true,
        Detonation: true,
        "Farligt föremål": true,
        Hemfridsbrott: true,
        "Hets mot folkgrupp": true,
        Knivlagen: true,
        Narkotikabrott: true,
        "Ofredande/föragelse": true,
        Ordningslagen: true,
        "Polisinsats/kommendering": true,
        Räddningsinsats: true,
        "Sabotage mot blåljusverksamhet": true,
        Sedlighetsbrott: true,
        Skottlossning: true,
        Skyddslagen: true,
        Vapenlagen: true,
      },
    },
    "4064d5cc-6e36-42a3-a82b-3e76c7bf53b2": {
      label: "Brott mot personer",
      active: true,
      subCategories: {
        "Larm Överfall": true,
        Misshandel: true,
        "Mord/dråp": true,
        "Olaga frihetsberövande/människorov": true,
        "Olaga hot": true,
        Rån: true,
        "Våld/hot mot tjänsteman": true,
        Våldtäkt: true,
        "Vållande till kroppsskada": true,
      },
    },
    "e0dd3e09-a12c-4357-9917-1c86b355f58b": {
      label: "Egendomsbrott",
      active: true,
      subCategories: {
        Bedrägeri: true,
        Förfalskningsbrott: true,
        Häleri: true,
        Inbrott: true,
        "Missbruk av urkund": true,
        "Olaga intrång": true,
        Skadegörelse: true,
        Stöld: true,
        "Stöld/inbrott": true,
      },
    },
    "185b5f1b-12c2-4490-8524-194b9f55a865": {
      label: "Trafikrelaterade händelser",
      active: true,
      subCategories: {
        Luftfartslagen: true,
        Motorfordon: true,
        "Olovlig körning": true,
        Rattfylleri: true,
        Sjölagen: true,
        Trafikbrott: true,
        Trafikhinder: true,
        Trafikkontroll: true,
        Trafikolycka: true,
      },
    },
    "4bbbfbd6-467c-4287-9ec9-48466c4c5fb6": {
      label: "Övriga kategorier",
      active: true,
      subCategories: {
        Alkohollagen: true,
        "Anträffad död": true,
        "Anträffat gods": true,
        Arbetsplatsolycka: true,
        Brand: true,
        Djur: true,
        "Efterlyst person": true,
        Fjällräddning: true,
        "Försvunnen person": true,
        "Fylleri/LOB": true,
        "Kontroll person/fordon": true,
        "Lagen om hundar och katter": true,
        Miljöbrott: true,
        "Sjukdom/olycksfall": true,
        "Spridning smitta/kemikalier": true,
        Utlänningslagen: true,
        "Varningslarm/haveri": true,
        Åldringsbrott: true,
        Övrigt: true,
      },
    },
  });

  function toggleFilter(categoryId, subCategory) {
    const category = filter[categoryId];

    if (!subCategory) {
      // Main category
      const newState = !category.active;
      const subCategories = category.subCategories;

      // Set all detailed categories to the main category state
      Object.keys(subCategories).forEach(
        (subCategory) => (subCategories[subCategory] = newState)
      );

      setFilter({
        ...filter,
        [categoryId]: { ...category, active: newState, subCategories },
      });
    } else {
      // Subcategory
      const newState = !category.subCategories[subCategory];
      let mainCategoryState = false;

      // Check the state of the activated subcategory and update the main category state if applicable:
      // - Enabling ALL subcategories will also enable the main category
      // - Disable ANY subcategory will also disable the main category
      if (newState) {
        mainCategoryState = true;
        const subCategories = Object.keys(category.subCategories);
        for (let i = 0; i < subCategories.length; i++) {
          const subCategoryState = category.subCategories[subCategories[i]];

          if (!subCategoryState && subCategories[i] !== subCategory) {
            mainCategoryState = false;
            break;
          }
        }
      }

      setFilter({
        ...filter,
        [categoryId]: {
          ...category,
          active: mainCategoryState,
          subCategories: { ...category.subCategories, [subCategory]: newState },
        },
      });
    }
  }

  return [filter, toggleFilter];
}
