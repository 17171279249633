import styles from "./FilterPanel.module.css";
import { FilterCategoryAccordion } from "../filterCategoryAccordion/FilterCategoryAccordion";

export function FilterPanel({ filter, toggleFilter }) {
  return (
    <div className={styles.root}>
      {Object.keys(filter).map((categoryId) => {
        return (
          <FilterCategoryAccordion
            key={categoryId}
            id={categoryId}
            filter={filter}
            toggleFilter={toggleFilter}
          />
        );
      })}
    </div>
  );
}
