import http from "./easyHTTP";

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export async function getFeed() {
  const feed = await http.get(`${backendBaseUrl}/items/queue`);
  return feed;
}

export async function getItems(lat, lng, west, east, south, north) {
  const items = await http.get(
    `${backendBaseUrl}/items?lat=${lat}&lng=${lng}&west=${west}&east=${east}&south=${south}&north=${north}`
  );
  return items;
}

export async function getItemDetails(uuid, lat, lng) {
  const details = await http.get(
    `${backendBaseUrl}/items/details?${
      uuid ? `uuid=${uuid}` : `lat=${lat}&lng=${lng}`
    }`
  );
  return details;
}

export async function saveItem(item) {
  const details = await http.post(
    `${backendBaseUrl}/items/${encodeURIComponent(item.uuid)}`,
    item
  );
  return details;
}

export async function searchLocation(query) {
  const location = await http.get(
    `${backendBaseUrl}/location?q=${encodeURIComponent(query)}`
  );

  return location && location[0] ? location[0] : null;
}

export async function searchAutocomplete(query) {
  const suggestions = await http.get(
    `${backendBaseUrl}/location/autocomplete?q=${encodeURIComponent(query)}`
  );

  return suggestions;
}
