import { useState } from "react";
import styles from "./EventPopup.module.css";
import { Popup } from "react-leaflet";
import { PageCounter } from "../../pageCounter/PageCounter";
import { lineSpinner } from "ldrs";
lineSpinner.register();

export function EventPopup({ latlng, eventFilter, getEventDetails }) {
  const [details, setDetails] = useState();
  const [page, setPage] = useState(0);
  const event = details ? details[page] : {};
  const title = formatTitle(event.title, event.publishedDate);
  const age = Math.ceil(
    (Date.now() - event.publishedDate) / 1000 / 60 / 60 / 24
  );

  const prevPage = () => setPage(page > 0 ? page - 1 : details.length - 1);
  const nextPage = () => setPage(page < details.length - 1 ? page + 1 : 0);

  // Fetch event details at coordinates from backend
  async function loadDetails() {
    const eventDetails = await getEventDetails(
      null,
      latlng.latitude,
      latlng.longitude
    );
    const filteredEvents = [];

    // Only add events whose categories match the current category filter
    eventDetails.forEach((e) => {
      if (
        eventFilter[e.filterCategory].active ||
        eventFilter[e.filterCategory]?.subCategories[e.category] ||
        e.filterCategory == null
      ) {
        filteredEvents.push(e);
      }
    });
    setDetails(filteredEvents);
  }

  return (
    <Popup
      eventHandlers={{ add: loadDetails }}
      keepInView={false}
      autoPan={true}
      closeOnClick={true}
    >
      <div className={styles.root}>
        {title ? (
          <>
            <h4>{title}</h4>
            <p>{event.longDescription}</p>
            <p>
              {details ? (
                <a
                  href={event.sourceUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={
                    age > 180
                      ? `${styles.reference} ${styles.invalid}`
                      : styles.reference
                  }
                >
                  {age <= 180
                    ? "Referens - polisen.se"
                    : "Referens - polisen.se"}
                </a>
              ) : null}
            </p>
            {details?.length > 1 ? (
              <PageCounter
                page={page}
                pageCount={details.length}
                prev={prevPage}
                next={nextPage}
              />
            ) : null}
          </>
        ) : (
          <l-line-spinner
            className={styles.loader}
            size={51}
            stroke={2}
            speed={1}
            color={"rgba(80, 80, 80, 0.95)"}
          ></l-line-spinner>
        )}
      </div>
    </Popup>
  );
}

// Transform title by removing update message and location
function formatTitle(title, publishedDate) {
  if (!title) return null;

  // Remove update text from title
  const titleArr = title.split(" ");
  if (titleArr[0] === "Uppdaterad") {
    titleArr.splice(0, 3);
  }
  titleArr.pop();

  // Get year from published date
  const published = new Date(0);
  published.setUTCMilliseconds(publishedDate);
  const year = published.getFullYear();

  return `${year}, ${titleArr.join(" ").slice(0, -1)}`;
}
