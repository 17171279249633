const mapboxApiToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

export const mapbox = {
  mapAttributes: {
    center: [63, 15],
    zoom: 5.3,
    zoomSnap: 0.1,
  },
  layerAttributes: {
    url: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: "mapbox/streets-v11",
    tileSize: 512,
    zoomOffset: -1,
    accessToken: mapboxApiToken,
  },
  areaAttributes: {
    severityColor: [
      "#BBFF00",
      "#FFEB00",
      "#FFCD00",
      "#FFA400",
      "#FF6F00",
      "#FF1800",
    ],
  },
  minZoom: 9,
};

export default mapbox;
