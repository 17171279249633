import styles from "./FilterCategoryAccordion.module.css";
import { useState } from "react";
import IconButton from "../iconButton/IconButton";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

export function FilterCategoryAccordion({ id, filter, toggleFilter }) {
  const [accordionState, setAccordionState] = useState(false);
  const category = filter[id];

  const toggleAccordion = () => setAccordionState(!accordionState);
  const updateMainCategory = () => toggleFilter(id);
  const updateSubCategory = (e) => toggleFilter(id, e.target.id);

  return (
    <div className={styles.root}>
      <div className={styles.mainCategory}>
        <IconButton
          id={`categoryFilterAccordion:${id}`}
          icon={accordionState ? FiChevronDown : FiChevronRight}
          width={21}
          height={21}
          onClick={toggleAccordion}
        />
        <input
          className={styles.checkbox}
          type="checkbox"
          id={id}
          name={category.label}
          checked={category.active}
          onChange={updateMainCategory}
        />
        <label htmlFor={id}>{category.label}</label>
      </div>
      {accordionState ? (
        // If the accordion is open (true), display the detailed category panel
        // with all included categories

        <div className={styles.accordionPanel}>
          {Object.keys(category.subCategories).map((subCategory) => {
            return (
              <div className={styles.subCategory} key={`${id}:${subCategory}`}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  id={subCategory}
                  name={subCategory}
                  checked={category.subCategories[subCategory]}
                  onChange={updateSubCategory}
                />
                <label htmlFor={subCategory}>{subCategory}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
