import { useContext, useState } from "react";
import styles from "./SearchBar.module.css";
import { EventContext } from "../context/EventContext";
import { searchLocation, searchAutocomplete } from "../../utils/api/api";
import { AutoCompletePanel } from "../autocompletePanel/AutocompletePanel";
import IconButton from "../iconButton/IconButton";
import { FiSearch, FiFilter } from "react-icons/fi";
import { lineSpinner } from "ldrs";
lineSpinner.register();

export function SearchBar({ updateMap, toggleFilterPanel }) {
  const [eventContext, setEventContext] = useContext(EventContext);
  const [autocompleteTimer, setAutocompleteTimer] = useState();
  const [autocompleteResults, setAutocompleteResults] = useState();
  const [searching, setSearching] = useState();

  function handleSearchChange(event) {
    if (autocompleteTimer) clearTimeout(autocompleteTimer);
    if (autocompleteResults !== "loading") setAutocompleteResults("loading");

    setAutocompleteTimer(
      setTimeout(() => {
        autocomplete(event.target.value);
      }, 700)
    );
  }

  async function autocomplete(query) {
    const searchResult = await searchAutocomplete(query);
    setAutocompleteResults(searchResult);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (autocompleteTimer) clearTimeout(autocompleteTimer);
    if (autocompleteResults) setAutocompleteResults(null);
    setSearching(true);

    const query = event.target[0].value;
    const searchResult = await searchLocation(query);

    if (searchResult) {
      goToSearchLocation(searchResult, query);
    }

    setSearching(false);
  }

  function goToSearchLocation(searchResult, query) {
    if (autocompleteTimer) clearTimeout(autocompleteTimer);
    if (autocompleteResults) setAutocompleteResults(null);

    setEventContext({
      ...eventContext,
      detailedLocation: query,
      latlng: [searchResult.lat, searchResult.lon],
      boundingBox: searchResult.boundingbox,
    });
    updateMap(null, [searchResult.lat, searchResult.lon]);
  }

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            id="search"
            type="text"
            placeholder="Sök här ..."
            autoComplete="off"
            autoFocus
            onChange={handleSearchChange}
          />
          <div className={styles.buttonContainer}>
            {searching ? (
              <l-line-spinner
                className={styles.loader}
                size={35}
                stroke={2}
                speed={1}
                color={"rgba(80, 80, 80, 0.95)"}
              ></l-line-spinner>
            ) : null}
            <IconButton
              type="submit"
              id="searchButton"
              icon={FiSearch}
              width={35}
              height={35}
            />
            <IconButton
              id="searchButton"
              icon={FiFilter}
              width={35}
              height={35}
              onClick={toggleFilterPanel}
            />
          </div>
        </label>
      </form>
      {autocompleteResults ? (
        <AutoCompletePanel
          suggestions={
            autocompleteResults === "loading" ? null : autocompleteResults
          }
          goToSearchLocation={goToSearchLocation}
        />
      ) : null}
    </div>
  );
}
