const get = async (url) => {
  try {
    const response = await fetch(url);
    return await response?.json();
  } catch (e) {
    console.log("Error running API: GET");
    return {};
  }
};

const post = async (url, data) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response?.text();
  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
};

const put = async (url, data) => {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response?.text();
  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
};

const patch = async (url, data) => {
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response?.text();
  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
};

const httpDelete = async (url) => {
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
    },
  });

  return response;
};

export const http = {
  get,
  post,
  put,
  patch,
  delete: httpDelete,
};

export default http;
